<template>
	<screen size="small" class="congrats-view">
		<template slot="body">
			<div class="congrats-header">
				<div class="roundup-logo"></div>
			</div>
			<div class="congrats-content">
				<div class="congrats-icon"></div>
				<h3>Thank You!</h3>
				<p>
					Your account is pending. We will notify you when you have
					been approved.
				</p>
			</div>
			<router-link :to="{ name: 'logout' }">Logout</router-link>
		</template>
	</screen>
</template>

<script>
import Screen from '../../ui/Screen'

export default {
	name: 'congrats-view',
	components: { Screen }
}
</script>

<style lang="scss">
@import '../../../styles/variables';

.congrats-view {
	padding: 60px 60px 40px 60px;
	text-align: left;
	.congrats-header {
		p {
			margin: 0px;
		}
	}
	.congrats-content {
		text-align: center;
		h3 {
			font-family: 'ff-tisa-web-pro';
			font-size: 24px;
			font-weight: 400;
		}
		.congrats-icon {
			background: url('../../../assets/congrats_icon.png');
			background-size: 100% 100%;
			width: 100px;
			height: 100px;
			display: inline-block;
		}
	}
	.roundup-logo {
		background: url('../../../assets/roundup_logo.svg');
		background-size: 137px 14px;
		background-repeat: no-repeat;
		background-position: center;
		width: 100%;
		height: 14px;
		margin: 0px 0px 20px;
	}
}
</style>
